<template>
  <div class="BuyBox">
    <ConfirmModal
      v-if="showConfirmAddToCart"
      :show-abort="true"
      @close="showConfirmAddToCart = false"
      @click-outside="showConfirmAddToCart = false"
      @confirm="addToCart"
    >
      <div class="p-32 pb-24 type-lg">
        {{ $t('productPage.buybox.confirmBackorderTitle', { date: estimatedDeliveryDate }) }}
      </div>
      <div class="wysiwyg pr-32 pl-24 pb-32">
        <ul>
          <li>
            {{ $t('productPage.buybox.confirmBackorderContentFirst') }}
          </li>
          <li>
            {{ $t('productPage.buybox.confirmBackorderContentSecond') }}
          </li>
        </ul>
      </div>
    </ConfirmModal>
    <MultiBuyDiscount
      :key="activeVariant.partNo"
      :active-variant="activeVariant"
      @change-campaign-type="changeCampaignType"
    />
    <TextInput
      v-if="activeVariant.squareMeterPerPackaging"
      v-model="squareMeter"
      class="w-full mb-12"
      type="text"
      :label="'Ange m²'"
    />
    <div v-if="validSquareMeter && activeVariant.squareMeterPerPackaging" class="type-sm mb-12">
      <p><span class="font-medium">Rekommenderat:</span> {{ recommendedQuantity }} st förpackningar</p>
      <small>Motsvarar {{ recommendedQuantity * squareMeterPerQuantity }} m² ({{ squareMeterPerQuantity }}² per förpackning)</small>
    </div>
    <small v-else-if="squareMeter && !validSquareMeter && activeVariant.squareMeterPerPackaging" class="text-warningDark">Ange kvadratmeter i siffror. Decimaltecken ska vara punkt (t.ex 12.5)</small>
    <div v-if="!activeVariant.productHasExpired" class="type-headline-sm">
      <div
        v-if="design !== 'quickbuy'"
        :class="{ 'hidden': disabledQuantityBtns,
        }"
      >
        {{ $t('productPage.sizeSelector.quantityLabel') }}
      </div>
      <div v-if="!disabledQuantityBtns" class="flex justify-left gap-8 desk:gap-12 items-end mt-4 select-none">
        <div class="border border-light flex flex-row h-40">
          <button
            class="w-40 h-40 flex items-center justify-center group"
            :class="{ 'pointer-events-none': totalQuantity === 1}"
            :disabled="totalQuantity === subOrAddQuantity"
            @click="totalQuantity = (totalQuantity - subOrAddQuantity)"
          >
            <img
              src="/icons/minus.svg"
              class="w-16 h-16 bg-lightest outline outline-4 desk:outline-8 outline-lightest
              group-hover:bg-brandLight group-hover:outline-brandLight transition-all"
              alt=""
            >
          </button>
          <div
            class="w-32 flex items-center justify-center type-headline-xs desk:type-headline-sm"
          >
            {{ totalQuantity }}
          </div>
          <button
            class="w-40 h-40 flex items-center justify-center group"
            :class="{
              'pointer-events-none': totalQuantity >= canBuyMoreAmount && canBuyMoreAmount > 0 || canBuyMoreAmount === 0 && cartStore.getQuantityFromPartNo(activeVariant.partNo) > 0
            }"
            :disabled="!globalStore.getAllowBackorders && totalQuantity + subOrAddQuantity > canBuyMoreAmount && !activeVariant.isOnDemandProduct"
            @click="totalQuantity = (totalQuantity + subOrAddQuantity)"
          >
            <img
              src="/icons/plus.svg"
              class="
        w-16 h-16 outline outline-4 desk:outline-8 outline-transparent transition-all
        group-hover:bg-brandLight group-hover:outline-brandLight"
              alt=""
            >
          </button>
        </div>
        <div
          v-for="bulkOption in [6, 12, 24]"
          :key="'bulk-option-' + bulkOption"
          class="w-auto h-40 shrink-0 type-headline-sm"
        >
          <button
            class="bg-lighter px-16 h-40 hover:bg-brandLight transition-all"
            :class="{'pointer-events-none': !globalStore.getAllowBackorders && !activeVariant.isOnDemandProduct && !activeVariant.isPreOrderProduct && canBuyMoreAmount < bulkOption}"
            :disabled="!globalStore.getAllowBackorders && !activeVariant.isOnDemandProduct && !activeVariant.isPreOrderProduct && canBuyMoreAmount < bulkOption"
            @click="totalQuantity = (totalQuantity = bulkOption)"
          >
            +{{ bulkOption }}
          </button>
        </div>
      </div>
    </div>
    <div v-if="userStore.isLoggedIn">
      <div v-if="!globalStore.getAllowBackorders && !activeVariant.isOnDemandProduct" class="type-sm-medium mt-8">
        <div
          v-if="canBuyMoreAmount === 0 && cartStore.getQuantityFromPartNo(activeVariant.partNo) > 0"
          class="inline-flex relative p-12 items-center gap-6 bg-warningLight"
        >
          <img
            src="/icons/info.svg"
            class="w-16 h-16"
            alt="info"
          >
          {{ $t('stock.maxInCart') }}
        </div>
        <div
          v-else-if="totalQuantity >= canBuyMoreAmount && canBuyMoreAmount > 0"
          class="inline-flex relative p-12 items-center gap-6 bg-warningLight"
        >
          <div class="w-16 h-16 rounded-1 rotate-45 bg-warningLight absolute -top-4 left-48" />
          <img
            src="/icons/info.svg"
            class="w-16 h-16"
            alt="info"
          >
          {{ $t('stock.maxCanAdd', { num: canBuyMoreAmount }) }}
        </div>
      </div>
    </div>
    <div class="flex items-center type-xs my-16">
      <StockStatus :variant="activeVariant" :product-item="productItem" />
    </div>

    <!-- * * * * notify when back in stock -->
    <div v-if="!globalStore.getAllowBackorders && activeVariant && !activeVariant.canAddToCart && !activeVariant.productHasExpired && userStore.isLoggedIn">
      <div v-if="!stockAlertSuccess" class="relative h-48 mb-12">
        <TextInput
          v-model="outOfStockEmail"
          type="email"
          class="input h-48"
          :label="$t('productPage.stockAlertPlaceholder')"
          @keyup.enter="subscribeToStockUpdates"
        />
      </div>
      <div v-if="stockAlertError" class="text-criticalDark text-14 mb-12">
        {{ $t('productPage.stockAlertError') }}
      </div>
      <div
        v-if="stockAlertSuccess" 
        class="type-headline-sm text-center mb-12 p-24"
        :class="{
          'bg-successPrimary text-lightest': lastResponseCode !== 200,
          'bg-lighter': lastResponseCode === 200}"
      >
        {{ stockAlertSuccessText ?? $t('productPage.stockAlertSuccess') }}
      </div>
    </div>

    <!-- * * * * expired product - show related -->
    <div v-if="activeVariant.productHasExpired">
      <div v-if="relatedList.length" class="type-sm-medium mb-12 uppercase">
        {{ $t('productPage.similarProducts.title') }}
        <ProductRelatedProductsList :list="relatedList" :show-buy-btns="false" />
      </div>
    </div>

    <!-- * * * * active product -->
    <div v-else>
      <div v-if="!stockAlertSuccess" class="flex">
        <button
          v-if="!userStore.isLoggedIn"
          class="btn btn--lg flex-1 w-full btn"
          @click.prevent="showLogin"
        >
          {{ $t('priceInfo.loginForPriceInfo') }}
        </button>
        <!-- logged in but cant be added to cart -->
        <button
          v-else-if="!globalStore.getAllowBackorders && !props.activeVariant.canAddToCart"
          class="btn btn--lg flex-1 w-full mb-24 btn--secondary"
          :disabled="!validNotifyMail"
          @click="subscribeToStockUpdates"
        >
          {{ $t('notify.openModalButton') }}
        </button>

        <Tooltip
          v-else-if="userStore.isFinanceUser"
          :show-on-hover="true"
          class="ml-8 w-full"
          :text="$t('priceInfo.financeUserTooltip')"
        >
          <button
            class="btn btn--lg flex-1 w-full mb-24 btn"
            :class="{
              'pointer-events-none': true,
            }"
            :disabled="true"
          >
            {{ addToCartLabel }}
            <span
              v-if="totalSum > 0"
              class="ml-8 text-12 normal-case"
            >
              {{ totalSum }} kr
            </span>
          </button>
        </Tooltip>

        <!-- * * * * actual buy button -->
        <button
          v-else
          class="btn btn--lg flex-1 w-full btn"
          :class="{
            'loading': loading,
            'pointer-events-none': disabledBuyButton,
          }"
          :disabled="disabledBuyButton"
          @click="maybeConfirmAddToCart"
        >
          {{ addToCartLabel }}
          <span
            v-if="totalSum > 0"
            class="ml-8 type-tagline"
          >
            {{ totalSum.toFixed(2) }} kr
          </span>
        </button>

        <!-- * * * * favorite button -->
        <button
          v-if="userStore.canFavorizeProducts"
          class="w-48 h-48 px-12 ml-12 shrink-0 border hover:bg-lightest hover:border-darkest transition-all"
          :class="{
            'bg-lighter border-lighter' : !isFavorite,
            'bg-lightest border border-light' : isFavorite,
          }"
          @click="addOrDeleteFavorite"
        >
          <img
            alt=""
            :src="showStar ? '/icons/heart-filled.svg' : '/icons/heart.svg'"
            class="h-24 w-24"
            :class="{
              'favoriteAnimateIn': loadingToTrue,
            }"
          >
        </button>
      </div>
      <p class="text-center type-xs my-12">ELLER</p>
      <button
        v-if="activeVariant.squareMeterPerPackaging"
        class="btn btn--secondary btn--lg w-full btn"
      >
        Beställ gratis golvprov
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ProductModel } from '~/models/product';
import { useCartStore } from '~/store/cart';
import { useUserStore } from '~/store/user';
import { useGlobalContentStore } from '~/store/globalContent';
import { useProductStore } from '~/store/product';
import { useUiStore } from '~/store/ui';
import StockStatus from '~/components/product/StockStatus.vue';
import ProductRelatedProductsList from '~/components/product-page/ProductRelatedProductsList.vue';
import ProductVariant, { ProductVariantModel } from '~/models/productVariant';
import useApiFetch from '~/composeables/useApiFetch';
import { NorceRelatedProductTypeCodes } from '~/constants/norceCodes';
import MultiBuyDiscount from '~/components/product-page/MultiBuyDiscount.vue';
import Tooltip from '~/components/body/Tooltip.vue';
import { CampaignItem } from '~/constants/types/multiDiscount';
import useMultiDiscount from '~/composeables/useMultiDiscount';
import TextInput from '~/components/form-elements/TextInput.vue';
import ConfirmModal from '~/components/modals/ConfirmModal.vue';

const userStore = useUserStore();
const uiStore = useUiStore();
const cartStore = useCartStore();
const productStore = useProductStore();
const globalStore = useGlobalContentStore();
const { $t } = useNuxtApp();
const totalQuantity = ref(1);
const subOrAddQuantity = ref(1);
const relatedList = ref<ProductModel[]>([]);

interface Props {
  activeVariant: ProductVariantModel & { name: string },
  productItem: ProductModel,
  disabled?: boolean
  crossell?: boolean,
  design: 'standard' |'quickbuy',
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  crossell: false,
  design: 'standard',
});

const { percentageCampaigns, hasCampaign, giftCampaigns } = useMultiDiscount({ activeVariant: props.activeVariant });

const priceListLocked = ref(hasCampaign && !giftCampaigns.value.length ? true : false);

const disabledQuantityBtns = computed(() => {
  return (!userStore.isLoggedIn || props.activeVariant.isSoldOut) && !props.activeVariant.isOnDemandProduct && !props.activeVariant.isPreOrderProduct && !globalStore.getAllowBackorders;
});

const canBuyMoreAmount = computed(()=> {
  const cartQty = cartStore.getQuantityFromPartNo(props.activeVariant.partNo);
  const qty = props.activeVariant.stockValue - cartQty;

  if (props.activeVariant.hasFixedRecommendedQty && (props.activeVariant.recommendedQty && qty > props.activeVariant.recommendedQty)) {
    if ((cartQty + totalQuantity.value) > props.activeVariant.recommendedQty) {
      return 0;
    }
    return props.activeVariant.recommendedQty;
  }

  return qty;
});

const disabledBuyButton = computed(() => {
  if (userStore.isFinanceUser) {
    return true;
  } else if (props.disabled) {
    return true;
  } else if (props.productItem.productUnavailable) {
    return true;
  } else if (globalStore.getAllowBackorders) {
    return false;
  } else if (props.activeVariant.isInStock || props.activeVariant.isPreOrderProduct || props.activeVariant.isOnDemandProduct || props.activeVariant.hasIncomingDate || props.activeVariant.isSoldOut) {
    return false;
  }

  return true;
});

const addToCartLabel = computed(() => {
  if (globalStore.getAllowBackorders) {
    return $t('productPage.sizeSelector.addToCartBtn');
  } else if (props.activeVariant.isPreOrderProduct) {
    return $t('productPage.sizeSelector.preOrder');
  } else if (props.activeVariant.isTempSoldOut) {
    return $t('productPage.sizeSelector.watchStockBtn');
  } else if (props.activeVariant.isOnDemandProduct || (props.activeVariant && props.activeVariant.canAddToCart) || props.activeVariant.hasIncomingDate) {
    return $t('productPage.sizeSelector.addToCartBtn');
  }
  return $t('productPage.sizeSelector.watchStockBtn');
});

const totalSum = computed((): number => {
  if ((!globalStore.getAllowBackorders && props.activeVariant.canAddToCart) || (globalStore.getAllowBackorders && !props.activeVariant.productHasExpired)) {
    if (userStore.getPrice(props.activeVariant.partNo, props.crossell).status === 'ok') {
      const basePrice = priceListLocked.value
        ? Number(userStore.getPrice(props.activeVariant.partNo, props.crossell).price?.priceBeforeDiscountBeforeVat) ?? 0
        : Number(userStore.getPrice(props.activeVariant.partNo, props.crossell).price?.priceBeforeVat) ?? 0;

      if (percentageCampaigns.value.length > 0) {
        const highestLimitReachedTrue = percentageCampaigns.value
          .filter(item => totalQuantity.value >= item.limit)
          .reduce<CampaignItem | null>((acc, item) => {
            return !acc || item.limit > acc.limit ? item : acc;
          }, null);

        return highestLimitReachedTrue ? totalQuantity.value * basePrice * ((100 - highestLimitReachedTrue.gainedAmount) / 100) : totalQuantity.value * basePrice;
      } else {
        return totalQuantity.value * basePrice;
      }
    }
  }

  return 0;
});

const showLogin = () => {
  uiStore.setShowSigninModal(true, true);
};

const emit = defineEmits<{
  (e: 'changeSize', size: ProductVariant & { name: string }): void;
  (e: 'addToCart'): void;
  (e: 'addingToCart'): void;
}>();

const outOfStockEmail = userStore.isLoggedIn ? ref(userStore.userProfile.email) : ref('');

const { apiPost, lastResponseCode } = useApiFetch();
const loadingStockAlert = ref(false);
const stockAlertSuccess = ref(false);
const stockAlertSuccessText = ref<string | null>(null);
const stockAlertError = ref(false);
const subscribeToStockUpdates = async() => {
  if (validNotifyMail.value) {
    loadingStockAlert.value = true;
    stockAlertError.value = false;
    stockAlertSuccess.value = false;
    stockAlertSuccessText.value = null;
    const res = await apiPost(`/products/${props.activeVariant.partNo}/stock-alerts`, {
      email: outOfStockEmail.value,
    });
    if (res) {
      stockAlertSuccess.value = true;
      if (lastResponseCode.value === 200) {
        stockAlertSuccessText.value = $t('productPage.stockAlertSuccessAgain');
      }

      setTimeout(()=> stockAlertSuccess.value = false, 10000);
    } else {
      stockAlertError.value = true;
    }

    loadingStockAlert.value = false;
  } else {
    console.warn('not valid');
  }
};

const validNotifyMail = computed(()=> {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return regex.test(outOfStockEmail.value);
});

const loading = ref(false);
const showConfirmAddToCart = ref(false);

const maybeConfirmAddToCart = async() => {
  if (isBackorderProduct.value) {
    showConfirmAddToCart.value = true;
  } else {
    await addToCart();
  }
};

const addToCart = async() => {
  loading.value = true;
  emit('addingToCart');

  if ((!globalStore.getAllowBackorders && (props.activeVariant.canAddToCart || props.activeVariant.hasIncomingDate)) || globalStore.getAllowBackorders && !props.activeVariant.productHasExpired) {
    await cartStore.updateCart(
      props.activeVariant.partNo,
      totalQuantity.value,
      -1,
      true,
      props.activeVariant,
      false,
      props.productItem.objectID,
      priceListLocked.value
    );
    emit('addToCart');
  }

  loading.value = false;
};

const loadingToTrue = ref(false);
const loadingToFalse = ref(false);

const addOrDeleteFavorite = async() => {
  if (isFavorite.value) {
    loadingToFalse.value = true;
    await userStore.updateFavoriteProductQuantity(props.activeVariant.partNo, 0);
    loadingToFalse.value = false;
  } else {
    loadingToTrue.value = true;
    await userStore.saveFavoriteProduct(props.activeVariant.partNo, totalQuantity.value);
    loadingToTrue.value = false;
  }
};

const showStar = computed(()=> {
  return (isFavorite.value && !loadingToFalse.value) || loadingToTrue.value;
});

const isFavorite = computed((): boolean => {
  return !!userStore.userProfile.favoriteProducts?.find(favorite => favorite.id === props.activeVariant.partNo);
});

const isBackorderProduct = computed(() => {
  return globalStore.getAllowBackorders && !props.activeVariant.isInStock;
});

const estimatedDeliveryDate = computed(() => {
  return props.activeVariant.incomingDate;
});

watch(
  () => props.activeVariant,
  () => {
    totalQuantity.value = 1;
    stockAlertError.value = false;
    stockAlertSuccess.value = false;
    if (props.activeVariant.productHasExpired) {
      setRelatedList();
    }
  }
);

onMounted(() => {
  if (props.activeVariant.productHasExpired) {
    setRelatedList();
  }
});

const setRelatedList = (async() => {
  const partNos = props.productItem.relatedProductsByType(NorceRelatedProductTypeCodes.Replacer);
  if (partNos.length > 0) {
    relatedList.value = await productStore.getRelatedProducts(partNos);
  }
});

const changeCampaignType = (type: boolean) => {
  if (!giftCampaigns.value.length) {
    priceListLocked.value = type;
  }
};

const squareMeter = ref('');
const squareMeterPerQuantity = ref<string>(props.activeVariant.squareMeterPerPackaging ?? 1.5);
const validSquareMeter = computed(() => {
  return squareMeter.value && squareMeter.value.match(/^[0-9]*\.?[0-9]*$/);
});
const recommendedQuantity = computed(() => {
  if (!validSquareMeter.value) {
    return 1;
  }
  const squareMeterPerQuantityFloat = parseFloat(squareMeterPerQuantity.value);
  const wanted = parseFloat(squareMeter.value.replace(',', '.'));
  let recommended = Math.round( wanted / squareMeterPerQuantityFloat);
  if (recommended * squareMeterPerQuantityFloat < wanted) {
    recommended += 1;
  }
  return recommended.toFixed(0);
});

watch(recommendedQuantity, (val) => {
  totalQuantity.value = parseInt(val, 10);
});
</script>

<style lang="postcss">

</style>
